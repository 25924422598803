import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import './styles.css';

const SelectGroup = ({ name, loading, placeholder, options, onChange }) => {
  const theme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#FF4136',
    },
  });

  const colourStyles = {
    control: styles => ({ ...styles, height: '2rem' }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? '#FF4136' : isSelected && isFocused ? '#FF4136' : isFocused ? '#fff3f2' : null,
    }),
  };

  return (
    <Form.Group className="select-block">
      <Select
        name={name}
        isLoading={loading}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        theme={theme}
        styles={colourStyles}
        noOptionsMessage={({ inputValue }) => ('Nenhum resultado...')}
        blurInputOnSelect
      /> 
    </Form.Group>
  );
};

export default SelectGroup;
