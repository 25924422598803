import React from 'react';

import { Card, Col, Container, Row } from 'react-bootstrap';

import './styles.css';

import capa01 from '../../assets/images/capa01.png';
import capa02 from '../../assets/images/capa02.png';
import capaCordel from '../../assets/images/CORDEL-MROSC-CAPA.jpg';
import capa27causos from '../../assets/images/capa_27_causos.png';

const Publications = () => {
  return (
    <section className="publications">
      <Container>
        <Row>
          <Col md={12}>
            <div className="publications-books-title">
              <h1>Leia nossas publicações</h1>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={9}>
            <div className="publications-buttons">
              <Card body>
                <a href="downloads/Livro-27-Causos.pdf" target="_blank" rel="noopener noreferrer">
                  <img src={capa27causos} alt="Livro 27 Causos" />
                </a>
              </Card>
              <Card body>
                <a href="downloads/Cordel-do-MROSC-site.pdf" target="_blank" rel="noopener noreferrer">
                  <img src={capaCordel} alt="Cordel do MROSC" />
                </a>
              </Card>
              <Card body>
                <a href="downloads/LivretoOSCLEGAL1web.pdf" target="_blank" rel="noopener noreferrer">
                  <img src={capa01} alt="OSC Legal 1" /> 
                </a>
              </Card>
              <Card body>
                <a href="downloads/LivretoOSCLEGAL2web.pdf" target="_blank" rel="noopener noreferrer">
                  <img src={capa02} alt="OSC Legal 2" />
                </a>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Publications;
