import React, { useState } from 'react';
import { Col, Container, Row, Collapse, Button } from 'react-bootstrap';

import SelectGroup from '../../components/SelectGroup';

import './styles.css';

const LandingModels = () => {
  const [textClass, setTextClass] = useState('');
  const [openEstatutoAssociacao, setOpenEstatutoAssociacao] = useState(false);
  const [openAtaAssembleiaGeral, setOpenAtaAssembleiaGeral] = useState(false);
  const [openEstatutoGruposCapoeira, setOpenEstatutoGruposCapoeira] = useState(false);
  const [openTermoVoluntariado, setOpentermoVoluntariado] = useState(false);
  const [openRequerimentoIsencaoTaxas, setOpenRequerimentoIsencaoTaxas] = useState(false);

  function handleHideText() {
    textClass !== 'hide' && setTextClass('hide');
  }

  const closeAllCollapse = () => {
    setOpenAtaAssembleiaGeral(false);
    setOpenEstatutoGruposCapoeira(false);
    setOpentermoVoluntariado(false);
    setOpenEstatutoAssociacao(false);
    setOpenRequerimentoIsencaoTaxas(false);
  };

  const handleCollapse = (value) => {
    handleHideText();
    closeAllCollapse();

    if (value === 'estatutoAssociacao') {
      setOpenEstatutoAssociacao(true);
    }
    if (value === 'ataAssembleiaGeral') {
      setOpenAtaAssembleiaGeral(true);
    }
    if (value === 'estatutoGruposCapoeira') {
      setOpenEstatutoGruposCapoeira(true);
    }
    if (value === 'termoVoluntariado') {
      setOpentermoVoluntariado(true);
    }
    if (value === 'requerimentoIsencaoTaxas') {
      setOpenRequerimentoIsencaoTaxas(true);
    }
  };

  return (
    <section id="models" className="models" >
      <Container fluid>
        <Row>
          <Col lg={6} md={12}>
            <div className="models-text">
              <h1>
                Encontre aqui <br />
                alguns modelos <br />
                para sua OSC
                <span className="red">.</span>
              </h1>
              <div className="models-select">
                <SelectGroup
                  name="models"
                  onChange={e => handleCollapse(e.value)}
                  placeholder="Selecione um modelo"
                  options={[
                    {
                      value: "estatutoAssociacao",
                      label: "Estatuto Associação"
                    },
                    {
                      value: "ataAssembleiaGeral",
                      label: "Ata Assembléia Geral Associação"
                    },
                    {
                      value: "estatutoGruposCapoeira",
                      label: "Estatuto Grupos de Capoeira"
                    },
                    {
                      value: "termoVoluntariado",
                      label: "Termo de Voluntariado"
                    },
                    {
                      value: "requerimentoIsencaoTaxas",
                      label: "Requerimento de isenção de taxas"
                    },
                  ]}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} md={12}>
            <div className="models-content">
              <p className={`${textClass}`}>
                Nesta seção são apresentados alguns modelos de documentos necessários a gestão de uma OSC, em compatibilidade com o MROSC. Os documentos são meramente exemplificativos, portanto, recomendável verificar a legislação, especialmente aquela vigente no local de atuação da OSC, além da rotina dos cartórios competentes. Por fim, é sempre importante consultar uma assessoria jurídica competente.  
              </p>
              <Collapse in={openEstatutoAssociacao}>
                <div>
                  <div className="models-collapse">
                    <h2>Modelo Estatuto Associação</h2>
                    <p>
                      Documentação básica de uma associação, deve-se atentar para questões como a possibilidade de remuneração dos associados e dirigentes, tempo de mandato, organização interna, etc.
                    </p>
                    <Button
                      variant="osc"
                      href="/downloads/estatutos/OSC%20LEGAL%20Modelo%20Estatuto%20Associação.docx"
                    >Baixar</Button>
                  </div>
                </div>
              </Collapse>
              <Collapse in={openAtaAssembleiaGeral}>
                <div>
                  <div className="models-collapse">
                    <h2>Modelo Ata Assembléia Geral Associação</h2>
                    <p>
                      Modelo de ata de Assembléia Geral de Associação, que poderá ser adaptada e utilizada em qualquer pauta e contexto.
                    </p>
                    <Button
                      variant="osc"
                      href="/downloads/estatutos/OSC%20LEGAL%20Modelo%20Ata%20Assembleia%20Associacao%20Geral.docx"
                    >Baixar</Button>
                  </div>
                </div>
              </Collapse>
              <Collapse in={openEstatutoGruposCapoeira}>
                <div>
                  <div className="models-collapse">
                    <h2>Modelo Estatuto Grupos de Capoeira</h2>
                    <p>
                      Documentação básica para grupos de capoeira, com natureza jurídica de associação, deve-se atentar para questões como a possibilidade de remuneração dos associados e dirigentes, tempo de mandato, organização interna, etc.
                    </p>
                    <Button
                      variant="osc"
                      href="/downloads/estatutos/OSC%20LEGAL%20Modelo%20Estatuto%20Associação%20Grupo%20Capoeira.docx"
                    >Baixar</Button>
                  </div>
                </div>
              </Collapse>
              <Collapse in={openTermoVoluntariado}>
                <div>
                  <div className="models-collapse">
                    <h2>Modelo de Termo de Voluntariado</h2>
                    <p>
                      Termo obrigatório para registro do vínculo dos voluntários de uma OSC.
                    </p>
                    <Button
                      variant="osc"
                      href="/downloads/estatutos/OSC%20LEGAL%20Termo%20Adesão%20Voluntariado.docx"
                    >Baixar</Button>
                  </div>
                </div>
              </Collapse>
              <Collapse in={openRequerimentoIsencaoTaxas}>
                <div>
                  <div className="models-collapse">
                    <h2>Requerimento de isenção de taxas</h2>
                    <p>
                      Modelo que entidade pode utilizar para solicitar junto ao Oficial do Cartório ou Autoridade Judicial a gratuidade / isenção do pagamento das taxas / emolumentos  no registro da sua documentação.
                    </p>
                    <Button
                      variant="osc"
                      href="/downloads/estatutos/OSC%20LEGAL%20Modelo%20Requerimento%20Registro%20Ata%20Isencao%20Taxas.docx"
                    >Baixar</Button>
                  </div>
                </div>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LandingModels;
