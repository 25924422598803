import React, { useState, useEffect } from 'react';
import visit from 'unist-util-visit';
import Rehype from 'rehype';
import axios from 'axios';
import { Col, Container, Row, Button } from 'react-bootstrap';

import './styles.css';

const rehypeDefaults = {
  fragment: true,
  space: `html`,
  emitParseErrors: false,
  verbose: false,
};

let rehype = new Rehype().data(`settings`, rehypeDefaults);

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getPosts() {
      const mediumApi = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40osclegal';
  
      const result = await axios.get(mediumApi);
  
      let mediumPosts = getRecentPosts(result.data.items);

      mediumPosts = await mediumPosts.map((post, index) => {
        post.author = getAuthor(post.content);
        return post;
      });

      return setPosts(mediumPosts);
    }

    function getRecentPosts(posts) {
      const maxPosts = 4;
      let formattedPosts = [];
  
      posts.map((post, index) => {
        return (
          index < (maxPosts) && formattedPosts.push(post)
        );
      });
  
      return formattedPosts;
    }
  
    function getAuthor(postContent) {
      let extractedAuthor = '';
      let index = 0;
      const codeAST = rehype.parse(postContent);

      visit(codeAST, { tagName: 'p' }, (node) => {
        let result = node.children[0].value;
        index++;
        if (result) {
          result = result.replace('¹', '');
          result = result.replace('²', '');
          result = result.replace('³', '');
        }
    
        index === 1 && (extractedAuthor = result);
      });

      return extractedAuthor;
    }

    getPosts();
  }, []);

  if (!posts) {
    return (<p>Carregando...</p>)
  }

  return (
    <section className="blog">
      <Container fluid>
        <Row className="blog-row">
          <Col lg={6} md={12}>
            <div className="blog-text">
              <h1>
                VEJA <br />
                NOSSOS ARTIGOS <br />
                RECENTES
                <span className="red">.</span>
              </h1>
            </div>
          </Col>
          <Col lg={6} md={12} className="blog-content">
            <div className="blog-posts">
              {posts.map((post) => {
                return (
                  <div key={post.guid} className="blog-post">
                    <a href={post.link} target="_blank" rel="noopener noreferrer">
                      {post.title}
                    </a>
                    <h3>{post.author}</h3>
                  </div>
                );
              })}
            </div>
            <div className="blog-footer">
              <Button href="https://medium.com/@osclegal" target="_blank" variant="osc">Outras artigos</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Blog;
