import React from 'react';

import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';

import './styles.css';

const LandingVideo = () => {
  return (
    <section className="video">
      <Container>
        <Row className="justify-content-md-center">
          <Col md={9}>
            <div className="publications-video">
              <ResponsiveEmbed aspectRatio="16by9">
                <iframe title="mrosc" width="560" height="315" src="https://www.youtube.com/embed/ZzuKcMn6j1M" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </ResponsiveEmbed>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LandingVideo;
