import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import './styles.css';
import {loadAgenda} from "../../services/google-agenda-service";

const MONTH = ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"]
const STATUS = {loaded: 'loaded', loading: 'loading', error: 'error', idle: 'idle'}

const LandingAgenda = () => {
  const [status, setStatus] = useState(STATUS.idle);
  const [eventos, setEventos] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setStatus(STATUS.loading)
        const data = await loadAgenda()
        if ([200, 304].includes(data.status)) {
          setStatus(STATUS.loaded)
          setEventos(data.result.items)
        }
      } catch (error) {
        setStatus(STATUS.error)
      }
    })();
  }, []);

  const renderEvents = () => {
    console.log(eventos)
    return eventos.map((evento) => {
      const date = new Date(evento.start.dateTime)
      const day = date.getDate().toString().padStart(2, "0")
      const month = MONTH[date.getMonth()]
      const year = date.getFullYear()
      return (
        <div key={evento.id} className="agenda-event">
          <div className="agenda-event-date">
            <div>
              <span className="dayMonth">{day} {month}</span>
              <span className="year red">{year}</span>
            </div>
          </div>
          <div className="agenda-event-summary">
            <h3>{evento.summary}</h3>
            <span>{evento.description}</span>
          </div>
        </div>
      );
    })
  }

  const renderEmptyValue = () => {
    return (<div className="agenda-event">
      <p>Nenhum evento disponível no momento<span className="red">.</span></p>
    </div>)
  }

  return (
    <section id="agenda" className="agenda">
      <Container fluid>
        <Row>
          <Col lg={{span: 6, order: 'last'}} md={12} className="agenda-col">
            <div className="agenda-text">
              <h1>
                Participe dos <br/>
                nossos próximos <br/>
                eventos
                <span className="red">.</span>
              </h1>
            </div>
          </Col>
          <Col lg={{span: 6, order: 'first'}} md={12} className="agenda-content">
            <div className="agenda-events">
              {status === STATUS.loaded && renderEvents()}
              {(status === STATUS.loaded && !!!eventos.length) && renderEmptyValue()}
              {status === STATUS.loading && (<p>Carregando. Aguarde<span className="red">!</span></p>)}
              {status === STATUS.error && (<p>Entre em contato para maiores detalhes<span className="red">.</span></p>)}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default LandingAgenda;
