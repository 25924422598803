const init = () => {
  window.gapi.client.init({
    apiKey: process.env.REACT_APP_GOOGLE_CALENDAR_APIKEY,
    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  });
  loadAgenda().then(res => {
    return res
  })
}

export const loadGoogleClient = () => {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/client.js";
  script.async = true;
  script.onload = () => window.gapi.load("client", init);
  document.body.appendChild(script);
}

export const loadAgenda = () => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () => {
        const date = new Date()
        const today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
        window.gapi.client.request({
          path: `https://www.googleapis.com/calendar/v3/calendars/${process.env.REACT_APP_GOOGLE_CALENDAR_ID}/events?maxResults=${process.env.REACT_APP_GOOGLE_CALENDAR_MAX_EVENTS}&orderBy=startTime&singleEvents=true&timeMin=${today.toISOString()}`,
        }).then((data) => resolve(data), (error) => reject(error))
      }
      , 1000)
  })
};
