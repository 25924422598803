import React, { Component } from 'react';

import { Navbar } from 'react-bootstrap';
import { Link } from 'react-scroll';

import './styles.css';

import logo from '../../assets/images/logo.svg';

class navBar extends Component {
  state = {
    navClass: 'main-nav'
  };

  listenScrollEvent = e => {
    if (window.scrollY > 50) {
      this.setState({
        navClass: 'main-nav nav-scrolled'
      });
    } else {
      this.setState({
        navClass: 'main-nav'
      });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    return (
      <nav className={this.state.navClass}>
        <div className="nav-content">
          <Navbar bg="white" expand="lg">
            <div className="nav-brand">
              <Link to="root" spy={false} smooth={true} duration={800}>
                <img src={logo} alt="OSCLegal" height="34" className="d-inline-block align-top"/>
              </Link>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="nav-links">
                <Link to="about" activeClass="active" spy={true} smooth={true} offset={-2}duration={800}>Quem somos</Link>
                {/* <Link to="services" activeClass="active" spy={true} smooth={true} offset={3} duration={800}>O que fazemos</Link> */}
                <Link to="agenda" activeClass="active" spy={true} smooth={true} offset={5} duration={800}>Agenda</Link>
                <Link to="blog" activeClass="active" spy={true} smooth={true} offset={5} duration={800}>Blog</Link>
                <Link to="legislation" activeClass="active" spy={true} smooth={true} offset={3} duration={800}>Legislação</Link>
                <Link to="models" activeClass="active" spy={true} smooth={true} offset={5} duration={800}>Modelos</Link>
                <Link to="publications" activeClass="active" spy={true} smooth={true} offset={5} duration={800}>Publicações</Link>
                <Link to="contact" activeClass="active" spy={true} smooth={true} offset={5} duration={800}>Contato</Link>
                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </nav>

    );
  }
};

export default navBar;
