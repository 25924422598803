import React from 'react';
//import { Col, Container, Row } from 'react-bootstrap';
import { FaMedium, FaTwitter, FaYoutube, FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";

import './styles.css';

const BannerArea = () => {
  return (
    <section className="banner-area">
      {/* <Container>
        <Row className="banner-content">
          <Col lg={9} md={12}>
            <h1>
              Junto <br />
              com <br />
            </h1>
            <h1 className="underline">você</h1>
            <h1 className="dot">.</h1>
          </Col>
        </Row>
      </Container> */}
      <div className="banner-footer">
        <div className="icon-bar">
          <div>
            <a href="http://www.facebook.com/Osclegalbr/" target="_blank" rel="noopener noreferrer"><FaFacebookSquare color="#fff" size={18} /></a>
          </div>
          <div>
            <a href="https://www.instagram.com/osclegal/" target="_blank" rel="noopener noreferrer"><FaInstagram color="#fff" size={18} /></a>
          </div>
          <div>
            <a href="https://twitter.com/osclegal" target="_blank" rel="noopener noreferrer"><FaTwitter color="#fff" size={18} /></a>
          </div>
          <div>
            <a href="https://medium.com/@osclegal" target="_blank" rel="noopener noreferrer"><FaMedium color="#fff" size={18} /></a>
          </div>
          <div>
            <a href="https://" target="_blank" rel="noopener noreferrer"><FaLinkedin color="#fff" size={18} /></a>
          </div>
          <div>
            <a href="https://www.youtube.com/channel/UCGiS02T_lBX589OHOgTsjrw" target="_blank" rel="noopener noreferrer"><FaYoutube color="#fff" size={18} /></a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerArea;
