import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

// import lucasSeara from '../../assets/images/lucas-seara.jpeg';
// import thyanoCorreia from '../../assets/images/thyano-correia.png';

import './styles.css';

const About = () => {
  return (
    <section className="about">
      <Container fluid>
        <Row>
          <Col lg={12} md={12}>
            <div className="about-text">
              <h1>QUEM SOMOS</h1>
              <p>
                Somos o OSC LEGAL Instituto, uma iniciativa voltada ao fortalecimento das organizações da sociedade civil (OSC). Com o objetivo de colaborar com a constituição e gerenciamento das OSCs e sua relação com a Administração Pública e com o setor privado promovemos a troca de experiências, divulgação de informações úteis, produção e disseminação de conhecimentos relacionados à gestão social e ao direito.
              </p>
            </div>
          </Col>
        </Row>
        {/* <Row className="about-people-container">
          <Col lg={3} md={12}>
            <div className="about-people">
              <img src={lucasSeara} alt="Lucas Seara" height="320" className="d-inline-block align-top"/>
              <div className="people-description">
                <h3><a href="https://www.linkedin.com/in/lucas-seara-5b1a36102" target="_blank" rel="noopener noreferrer">Lucas Seara</a></h3>
                <h4>Diretor Geral</h4>
                <p>Advogado. Mestre em Desenvolvimento e Gestão Social (UFBA). Pai da Flora e apaixonado por música.</p>
              </div>
            </div>
          </Col>
          <Col lg={3} md={12}>
            <div className="about-people">
              <img src={thyanoCorreia} alt="Thyano Correia" height="320" className="d-inline-block align-top"/>
              <div className="people-description">
                <h3><a href="http://www.linkedin.com/in/thyano" target="_blank" rel="noopener noreferrer">Thyano Correia</a></h3>
                <h4>Diretor Geral</h4>
                <p>Analista de tecnologia informação, aspirante a administrador de organizações e apaixonado por viagens de desconexão.</p>
              </div>
            </div>
          </Col>
        </Row> */}
      </Container>
    </section>  
  );
};

export default About;
