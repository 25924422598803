/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import { VscLaw } from "react-icons/vsc";
import { Col, Container, Row, Button, Form, Collapse } from 'react-bootstrap';

import SelectGroup from '../SelectGroup';
import LegislationTable from '../LegislationTable';

import data from '../../data/data.json';

import brazilMap from '../../assets/images/mapa_brasil.svg';

import './styles.css';

const Legislation = () => {
  const [formClass, setFormClass] = useState('');
  const [state, setState] = useState(null);
  const [municpalState, setMunicipalState] = useState(null);
  const [city, setCity] = useState(null);
  const [federalDocuments, setFederalDocuments] = useState([]);
  const [stateDocuments, setStateDocuments] = useState([]);
  const [cityDocuments, setCityDocuments] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [municipalStateOptions, setMunicipalStateOptions] = useState([]);
  const [municipalCityOptions, setMunicipalCityOptions] = useState([]);
  const [openFederal, setOpenFederal] = useState(false);
  const [openEstadual, setOpenEstadual] = useState(false);
  const [openEstadualTable, setOpenEstadualTable] = useState(false);
  const [openMunicipal, setOpenMunicipal] = useState(false);
  const [openMunicipalTable, setOpenMunicipalTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const backendUrl = process.env.REACT_APP_URL_BACKEND;

  function handleHideLawIcon() {
    formClass !== 'hide' && setFormClass('hide');
  }

  const getFederalDocuments = async () => {
    const result = await axios.get(`${backendUrl}/documentos`, {
      params: {
        esfera: 'Federal',
        page: 0,
        limit: 20
      }
    });

    setFederalDocuments(result.data.documentos);
  }

  const getStateOptions = async () => {
    setLoading(true);
    const statesAbreviation = await axios.get(`${backendUrl}/documentos/ufs`, {
      params: {
        esfera: 'Estadual'
      }
    });
    const Resultoptions = await statesAbreviation.data.map(async stateAbreviation => {
      const state = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateAbreviation}`);

      return {
        value: stateAbreviation,
        label: state.data.nome
      };
    });

    const options = await Promise.all(Resultoptions)

    setLoading(false);
    setStateOptions(options);
  }

  const getStateDocuments = async (stateValue) => {
    const result = await axios.get(`${backendUrl}/documentos`, {
      params: {
        esfera: 'Estadual',
        uf: stateValue
      }
    });

    setStateDocuments(result.data.documentos);
  }

  const getCityDocuments = async (cityValue, stateValue) => {
    const result = await axios.get(`${backendUrl}/documentos`, {
      params: {
        esfera: 'Municipal',
        uf: stateValue,
        municipio: cityValue
      }
    });

    console.log(result.data)

    setCityDocuments(result.data.documentos);
  }

  const getMunicipalStateOptions = async () => {
    setLoading(true);
    const statesAbreviation = await axios.get(`${backendUrl}/documentos/ufs`, {
      params: {
        esfera: 'Municipal'
      }
    });
    const Resultoptions = await statesAbreviation.data.map(async stateAbreviation => {
      const state = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateAbreviation}`);

      return {
        value: stateAbreviation,
        label: state.data.nome
      };
    });

    const options = await Resultoptions;

    setMunicipalStateOptions(await Promise.all(options));
    setLoading(false);
  }

  const getMunicipalCityOptions = async (stateValue) => {
    const cities = await axios.get(`${backendUrl}/documentos/municipios`, {
      params: {
        uf: stateValue
      }
    });
    const options = cities.data.map(city => ({
        value: city,
        label: city
    }));

    setMunicipalCityOptions(options);
  }

  return (
    <section  className="legislation">
      <Container fluid>
        <Row>
          <Col lg={6} md={12} className="legislation-header">
            <div className="legislation-text">
              <h1>
                Encontre aqui <br />
                a legislação da <br />
                sua região
                <span className="red">.</span>
              </h1>
            </div>
            <Container>
              <Row>
                <Col xs={4}>
                  <Button
                    variant="osc"
                    onClick={() => {
                      getFederalDocuments();
                      openEstadual && setOpenEstadual(false);
                      
                      openEstadualTable && setOpenEstadualTable(false);
                      
                      openMunicipal && setOpenMunicipal(false);

                      openMunicipalTable && setOpenMunicipalTable(false);

                      !openFederal && setOpenFederal(true);

                      handleHideLawIcon();
                    }}
                    aria-controls="legislation-federal-table"
                    aria-expanded={openFederal}
                  >
                    Federal
                  </Button>
                </Col>
                <Col xs={4}>
                  <Button
                    variant="osc"
                    onClick={() => {
                      getStateOptions();
                      openFederal && setOpenFederal(false);
                      
                      openEstadualTable && setOpenEstadualTable(false);
                      
                      openMunicipal && setOpenMunicipal(false);

                      openMunicipalTable && setOpenMunicipalTable(false);

                      !openEstadual && setOpenEstadual(true);

                      handleHideLawIcon();
                    }}
                    aria-controls="legislation-form"
                    aria-expanded={openEstadual}
                  >
                    Estadual
                  </Button>
                </Col>
                <Col xs={4}>
                  <Button
                    variant="osc"
                    onClick={() => {
                      getMunicipalStateOptions();
                      openFederal && setOpenFederal(false);
                      
                      openEstadual && setOpenEstadual(false);
                      
                      openEstadualTable && setOpenEstadualTable(false);

                      openMunicipalTable && setOpenMunicipalTable(false);

                      !openMunicipal && setOpenMunicipal(true);

                      handleHideLawIcon();
                    }}
                    aria-controls="legislation-municipal-form"
                    aria-expanded={openMunicipal}
                  >
                    Municipal
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={6} md={12}>
            <div className={`law-icon ${formClass}`}>
              {/* <VscLaw size={128} color="#FF4136"/> */}
              <img src={brazilMap} alt="Mapa do Brasil"/>
            </div>
            <Collapse in={openFederal}>
              <div id="legislation-federal-table" className={`legislation-table`}>
                <LegislationTable
                  documents={federalDocuments}
                />
              </div>
            </Collapse>
            <Collapse in={openEstadual}>
              <div id="legislation-form" className={`legislation-form`}>
                <Form>
                  <Form.Row>
                    <Col lg={6} md={12}>
                      <SelectGroup 
                        name="state"
                        loading={loading}
                        placeholder="Selecione o estado"
                        onChange={e => {
                          setState(e.value);

                          setOpenEstadualTable(true);

                          getStateDocuments(e.value);
                        }}
                        options={stateOptions}
                      />
                    </Col>
                  </Form.Row>
                </Form>
              </div>
            </Collapse>
            <Collapse in={openEstadualTable}>
              <div id="legislation-state-table" className={`legislation-table`}>
                <LegislationTable
                  documents={stateDocuments}
                />
              </div>
            </Collapse>
            <Collapse in={openMunicipal}>
              <div id="legislation-municipal-form" className={`legislation-form`}>
                <Form>
                  <Form.Row>
                    <Col lg={6} md={12}>
                      <SelectGroup 
                        name="state"
                        loading={loading}
                        placeholder="Selecione o estado"
                        onChange={e => {
                          setMunicipalState(e.value);

                          getMunicipalCityOptions(e.value);
                        }}
                        options={municipalStateOptions}
                      />
                    </Col>
                    <Col lg={6} md={12}>
                      <SelectGroup
                        name="city"
                        loading={loading}
                        placeholder="Selecione a cidade"
                        onChange={e => {
                          setCity(e.value);

                          setOpenMunicipalTable(true);

                          getCityDocuments(e.value, municpalState)
                        }}
                        options={municipalCityOptions}
                      />
                    </Col>
                  </Form.Row>
                </Form>
              </div>
            </Collapse>
            <Collapse in={openMunicipalTable}>
              <div id="legislation-municipal-table" className={`legislation-table`}>
                <LegislationTable
                  documents={cityDocuments}
                />
              </div>
            </Collapse>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Legislation;
