import React, { useState } from 'react';
import { FaMedium, FaTwitter, FaYoutube, FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';

import icnl from '../../assets/images/icnl.svg';
import ecos from '../../assets/images/ecos.svg';
import mrosc from '../../assets/images/LOGO_MROSC.svg';

import './styles.css';

const Contact = () => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");

  const sendMail = async (e) => {
    e.preventDefault();

    const data = {
      nome,
      email,
      mensagem,
      token: process.env.REACT_APP_TOKEN
    };

    await axios.post(`${process.env.REACT_APP_URL_BACKEND}/enviar_email`, data);

    toast.success("Mensagem enviada com sucesso!", {position: "bottom-center"});

    setNome("");
    setEmail("");
    setMensagem("");
  };

  return (
    <section className="contact">
      <Container>
        <Row>
          <Col lg={6} md={12}>
            <div className="contact-text">
              <h1>
                Fale com a gente
                <span className="red">.</span>
              </h1>
              <p className="bold">osclegal@gmail.com</p>
              <p>+55 71 9 9694-4511</p>
              <p>+55 11 9 6631-3783</p>
              <div className="contact-social">
                <a href="https://www.facebook.com/Osclegalbr/" target="_blank" rel="noopener noreferrer"><FaFacebookSquare color="#fff" size={36} /></a>
                <a href="https://www.instagram.com/osclegal/" target="_blank" rel="noopener noreferrer"><FaInstagram color="#fff" size={36} /></a>
                <a href="https://twitter.com/osclegal" target="_blank" rel="noopener noreferrer"><FaTwitter color="#fff" size={36} /></a>
                <a href="https://medium.com/@osclegal" target="_blank" rel="noopener noreferrer"><FaMedium color="#fff" size={36} /></a>
                <a href="http://" target="_blank" rel="noopener noreferrer"><FaLinkedin color="#fff" size={36} /></a>
                <a href="https://www.youtube.com/channel/UCGiS02T_lBX589OHOgTsjrw" target="_blank" rel="noopener noreferrer"><FaYoutube color="#fff" size={36} /></a>
              </div>
            </div>
          </Col>
          <Col>
            <div className="contact-form">
              <Form onSubmit={e => sendMail(e)}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control 
                    type="text"
                    placeholder="Digite seu nome"
                    value={nome}
                    onChange={e => setNome(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="nome@exemplo.com.br"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />   
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Mensagem</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={mensagem}
                    onChange={e => setMensagem(e.target.value)}
                    required
                  />
                </Form.Group>
                <div className="contact-footer">
                  <Button variant="osc" type="submit">Envie sua dúvida</Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="contact-partners">
              <h1>NOSSOS PARCEIROS</h1>
              <div>
                <div className="partners-image">
                  <a href="https://www.icnl.org" target="_blank" rel="noopener noreferrer">
                    <img src={icnl} alt="ICNL"/>
                  </a>
                </div>
                <div className="partners-image">
                  <a href="https://www.ecos.org.br" target="_blank" rel="noopener noreferrer">
                    <img src={ecos} alt="ECOS"/>
                  </a>
                </div>
                <div className="partners-image">
                  <a href="http://plataformaosc.org.br" target="_blank" rel="noopener noreferrer">
                    <img src={mrosc} alt="MROSC"/>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
