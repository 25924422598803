import React from 'react';

import Navbar from '../../components/NavBar';
import BannerArea from '../../components/BannerArea';
import About from '../../components/About';
//import LandingServices from '../../components/LandingServices';
import Legislation from '../../components/Legislation';
import Blog from '../../components/Blog';
import LandingModels from '../../components/LandingModels';
import Publications from '../../components/Publications';
import Contact from '../../components/Contact';
import LandingAgenda from "../../components/LandingAgenda";
import LandingVideo from '../../components/LadingVideo';

import tiziiu from '../../assets/images/tiziiu.png';

import './styles.css';

const landing = () => (
  <>
    <Navbar />
    <BannerArea />
    <About name="about"/>
    {/* <LandingServices name="services" /> */}
    <LandingAgenda name="agenda"/>
    <Blog name="blog"/>
    <Legislation name="legislation"/>
    <LandingModels name="models"/>
    <LandingVideo name="video" />
    <Publications name="publications"/>
    <Contact name="contact"/>
    <footer>
      © {new Date().getFullYear()}, Desenvolvido por
      {` `}
      <a href="https://www.tiziiu.com" target="_blank" rel="noopener noreferrer"><img src={tiziiu} alt="Tiziiu"/></a>
    </footer>
  </>
);

export default landing;
