import React from 'react';
import { Table } from 'react-bootstrap';
import { FiDownload } from "react-icons/fi";

const LegislationTable = ({documents}) => {
  const checkUrl = (url) => {
    if (url.startsWith("nyc3")) {
      return 'https://' + url;
    } else {
      return url;
    }
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>DOCUMENTOS</th>
          <th>DOWNLOAD</th>
        </tr>
      </thead>
      <tbody>
        {documents.map(document => (
          <tr key={document.id}>
            <td>{document.nome}</td>
            <td>
              <a href={checkUrl(document.arquivos[0].url)} target="_blank" rel="noopener noreferrer"><FiDownload /></a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default LegislationTable;
