import React from 'react';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/styles/global.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <ToastContainer />
      <Routes />
    </>
  );
}

export default App;
